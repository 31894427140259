import React from 'react'

const Contact = (props) => (
<section id="contact">
        <div className="inner">
            <section className="split">

                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Email</h3>
                        <a href="#">solexcursion@gmail.com</a>
                    </div>
                    <div className="contact-method">
                        <span className="icon alt fa-phone"></span>
                        <h3>Téléphone</h3>
                        <span>06.44.24.36.96</span>
                    </div>
                    <div className="contact-method">
                        <span className="icon alt fa-home"></span>
                        <h3>Addresse</h3>
                        <span>Bertrand SIMON<br />
                        3, rue de bel air<br />
                        85150 La Mothe-Achard, Les Achards</span>
                    </div>
                </section>
            </section>
        </div>
    </section>
)

export default Contact
